import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Numbered from "../components/Headings/Numbered"
import Heading from "../components/Headings/Heading"
import SubHeading from "../components/Headings/SubHeading"
import Sidebar from "../components/Sidebar/Sidebar"
import {
  FaArrowLeft,
  FaBookReader,
  FaIdCard,
  FaSuitcase,
  FaToolbox,
  FaTools,
  FaWeight,
} from "react-icons/fa"
import styled from "styled-components"
import Accordion from "../components/Sidebar/Accordion"
import parseCategoryData from "../utils/parseCategoryData"
import Title from "../components/Headings/Title"

const Wrap = styled.div`
  max-height: 77vh;
  display: flex;
`

const InnerScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`

const ESPage = ({ data }) => {
  const subjectCategories = parseCategoryData(data.allMdx.edges)
  const [ToggleSidebar, setToggleSidebar] = useState(true)
  const toggle = () => setToggleSidebar(!ToggleSidebar)

  return (
    <Layout>
      <Seo title="Embedded Systems" />
      <section className="de-section">
        <Title>Embedded Systems</Title>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            {ToggleSidebar && (
              <div
                className="col-lg-3 mb-4"
                id="sticky-sidebar"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div
                  className="card shadow border-primary sticky-top"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-header text-center">
                    <h5 className="fat-text">Chapters</h5>
                  </div>
                  <Wrap>
                    <InnerScroll className="card-body small no-scrollbar">
                      <Accordion allowMultipleOpen>
                        {Object.keys(subjectCategories).map((category, i) => {
                          return (
                            <div key={i} label={category} isOpen>
                              <Sidebar
                                subjectLink={`embedded_systems`}
                                category={subjectCategories[category]}
                                target="ToggleSidebar"
                              />
                            </div>
                          )
                        })}
                      </Accordion>
                    </InnerScroll>
                  </Wrap>
                </div>
              </div>
            )}

            <div className={ToggleSidebar ? "col-lg-9 mb-4" : "col-lg-12 mb-4"}>
              <div
                className="card border-primary shadow"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-header">
                  <button
                    onClick={toggle}
                    id="ToggleSidebar"
                    className="btn btn-outline-primary btn-sm"
                  >
                    <FaArrowLeft /> Toggle Sidebar
                  </button>
                </div>

                <div className="card-body">
                  <Heading>What is Embedded Systems ?</Heading>

                  <p>
                    An embedded system is a computer system that is designed to
                    perform specific functions within a larger system. It is a
                    combination of hardware and software components that are
                    integrated together to form a self-contained system.
                    Embedded systems are used in a wide range of applications,
                    including consumer electronics, automotive systems, medical
                    devices, industrial automation, and many more.
                    <br />
                    <br />
                    The hardware components of an embedded system typically
                    include a microprocessor or microcontroller, memory,
                    input/output interfaces, and sensors. The software
                    components of an embedded system include firmware, operating
                    systems, and application software. The firmware is a type of
                    software that is closely integrated with the hardware and is
                    responsible for controlling the system's behavior. The
                    operating system is responsible for managing the system
                    resources and providing a user interface for the application
                    software.
                    <br />
                    <br />
                    Embedded systems are designed to perform specific functions
                    with high reliability, efficiency, and low power
                    consumption. They are often used in applications where
                    traditional computing systems are not suitable, such as in
                    remote locations, harsh environments, or real-time
                    applications. Embedded systems can be simple, such as a
                    thermostat in a home heating system, or complex, such as a
                    navigation system in an aircraft.
                    <br />
                    <br />
                    The design of embedded systems requires a deep understanding
                    of the hardware and software components, as well as the
                    application requirements. Embedded systems engineers need to
                    have expertise in electronics, programming, and system
                    design. They must also have knowledge of the application
                    domain, such as automotive systems, medical devices, or
                    industrial automation.
                    <br />
                    <br />
                    Overall, embedded systems are an essential component of many
                    modern technologies and play a critical role in enabling
                    automation and intelligence in various industries. The
                    increasing demand for smart and connected devices is
                    expected to drive the growth of embedded systems in the
                    future.
                    <br />
                  </p>

                  <Heading>
                    What is the difference between Microprocessor and
                    Microcontroller ?
                  </Heading>
                  <p>
                    Microprocessors and microcontrollers are both types of
                    integrated circuits that are used in embedded systems. While
                    they share some similarities, there are also several key
                    differences between them.
                    <br />
                    <br />
                    A microprocessor is a central processing unit (CPU) that is
                    designed to process data and perform arithmetic and logic
                    operations. It is typically used in general-purpose
                    computing applications, such as personal computers, servers,
                    and mobile devices. A microprocessor does not have any
                    built-in peripherals or memory, and it relies on external
                    components to perform these functions. Microprocessors are
                    designed for applications that require a high degree of
                    flexibility, where the system designer can select the
                    appropriate peripherals and memory to suit the application
                    requirements.
                    <br />
                    <br />
                    A microcontroller, on the other hand, is a single-chip
                    computer that combines a microprocessor, memory, and
                    input/output peripherals on a single chip. It is designed
                    for specific applications where cost, power consumption, and
                    size are critical factors. Microcontrollers are used in a
                    wide range of applications, including automotive systems,
                    consumer electronics, medical devices, and industrial
                    automation. Unlike microprocessors, microcontrollers have
                    built-in memory and peripherals, such as timers, serial
                    ports, and analog-to-digital converters, which makes them
                    well-suited for real-time and embedded applications.
                    <br />
                    <br />
                    The key differences between microprocessors and
                    microcontrollers are:
                    <br />
                    <br />
                    <SubHeading>Integration</SubHeading>
                    Microprocessors are designed as standalone CPUs, while
                    microcontrollers combine a CPU, memory, and peripherals on a
                    single chip.
                    <br />
                    <br />
                    <SubHeading>Flexibility</SubHeading>
                    Microprocessors offer a high degree of flexibility, where
                    the system designer can select the appropriate peripherals
                    and memory to suit the application requirements.
                    Microcontrollers are designed for specific applications, and
                    the peripherals and memory are typically fixed.
                    <br />
                    <br />
                    <SubHeading>Cost</SubHeading>
                    Microprocessors are generally more expensive than
                    microcontrollers due to their higher level of integration
                    and flexibility.
                    <br />
                    <br />
                    <SubHeading>Power Consumption</SubHeading>
                    Microcontrollers typically consume less power than
                    microprocessors due to their lower clock speeds and
                    integration of peripherals on a single chip.
                    <br />
                    <br />
                    In summary, the choice between a microprocessor and a
                    microcontroller depends on the specific application
                    requirements, including cost, power consumption, and
                    flexibility.
                    <br />
                  </p>

                  <Heading>Why you should learn embedded systems ?</Heading>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaWeight />
                        </div>
                        <h4>High Demand</h4>
                        <p>
                          Embedded systems are an essential part of modern
                          technology, from consumer electronics to medical
                          devices to automotive systems. As such, there is a
                          high demand for skilled embedded systems engineers.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaSuitcase />
                        </div>
                        <h4>Career Opportunities</h4>
                        <p>
                          Learning embedded systems can open up many career
                          opportunities, including roles in software
                          development, hardware design, system integration, and
                          testing.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaToolbox />
                        </div>
                        <h4>Innovations in Technology</h4>
                        <p>
                          Embedded systems play a critical role in the
                          development of new technologies and innovations, such
                          as smart homes, autonomous vehicles, and industrial
                          automation. Learning embedded systems can help you
                          contribute to these advancements.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaTools />
                        </div>
                        <h4>Hands-on Learning</h4>
                        <p>
                          Embedded systems development involves working with
                          hardware, software, and firmware, which provides a
                          unique hands-on learning experience. This can be
                          especially rewarding for people who enjoy working with
                          physical devices and problem-solving.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaIdCard />
                        </div>
                        <h4>Personal Growth</h4>
                        <p>
                          Learning embedded systems can help you develop new
                          skills, such as programming in low-level languages,
                          circuit design, and debugging. It can also help you
                          develop critical thinking skills and attention to
                          detail.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaBookReader />
                        </div>
                        <h4>Continuous Learning</h4>
                        <p>
                          Learning web development can be a lifelong pursuit,
                          keeping you engaged and up-to-date with the latest
                          trends and developments in the industry.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Heading>Who should learn embedded systems ?</Heading>
                  <p>
                    <SubHeading>Electronics engineers</SubHeading>
                    Embedded systems are used extensively in electronic devices
                    and systems, so electronics engineers can benefit from
                    learning about embedded systems.
                    <br />
                    <br />
                    <SubHeading>Computer engineers</SubHeading>
                    Embedded systems require software development skills, so
                    computer engineers can also benefit from learning about
                    embedded systems.
                    <br />
                    <br />
                    <SubHeading>Mechanical engineers</SubHeading>
                    Embedded systems are often used in mechatronic systems,
                    which combine mechanical and electronic components, so
                    mechanical engineers can benefit from learning about
                    embedded systems.
                    <br />
                    <br />
                    <SubHeading>Robotics engineers</SubHeading>
                    Embedded systems are critical components of robotics
                    systems, so robotics engineers can benefit from learning
                    about embedded systems.
                    <br />
                    <br />
                    <SubHeading>
                      Students pursuing engineering degrees
                    </SubHeading>
                    Students pursuing degrees in electrical engineering,
                    computer engineering, mechanical engineering, or robotics
                    can benefit from learning about embedded systems.
                    <br />
                    <br />
                    <SubHeading>Professionals in the tech industry</SubHeading>
                    Professionals in the tech industry who are interested in
                    expanding their skill set and knowledge base can also
                    benefit from learning about embedded systems.
                    <br />
                  </p>

                  <Heading>Learning Objectives</Heading>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>1</Numbered>
                        <h5 className="m-2">
                          Understanding the basic concepts and components of
                          embedded systems: This includes knowledge of
                          microprocessors, microcontrollers, sensors, actuators,
                          communication interfaces, and other components used in
                          embedded systems.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>2</Numbered>
                        <h5 className="m-2">
                          Developing programming skills for embedded systems:
                          This includes learning how to write code in
                          programming languages such as C or Assembly for
                          microcontrollers and microprocessors.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>3</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Understanding the design and development process for
                          embedded systems: This includes understanding the
                          requirements analysis, system design, hardware and
                          software development, and testing phases of an
                          embedded system project.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>4</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Familiarity with real-time systems and operating
                          systems: Embedded systems are often used in real-time
                          applications, and students should be familiar with
                          real-time system design and development. Additionally,
                          students should be familiar with operating systems
                          used in embedded systems, such as RTOS.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>5</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Knowledge of different types of embedded systems and
                          their applications: Students should understand the
                          different types of embedded systems, including
                          industrial control systems, automotive systems,
                          consumer electronics, and medical devices, among
                          others.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>6</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Familiarity with development tools and software:
                          Students should be familiar with development tools and
                          software used in the design and development of
                          embedded systems, such as compilers, debuggers, and
                          simulation software.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>7</Numbered>
                        <h5 className="m-2">
                          {" "}
                          Understanding of system integration and testing:
                          Students should be able to integrate hardware and
                          software components into a complete system and test it
                          for functionality, performance, and reliability.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ESPage

export const pageQuery = graphql`
  query ESPage {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/(embedded_systems)/.*.(mdx)/" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            topLevelDir
            subDir
          }
        }
      }
    }
  }
`
